<template>
  <div>
    <v-row justify="center">
      <div v-if="dialog && menu">
        <v-dialog
          :value="dialog"
          persistent
          max-width="50%"
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar>
              <v-btn icon @click="$emit('onCloseDialog')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title class="text-uppercase">
                {{
                  !menu._id ? $t("title.create_menu") : $t("title.update_menu")
                }}</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn :disabled="loading" text @click="validate">
                  {{ !menu._id ? $t("button.create") : $t("button.update") }}
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="menu.name"
                        :rules="rules.name"
                        :label="$t('input.menu_name')"
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="menu.descr"
                        :rules="rules.descr"
                        :label="$t('input.description')"
                        hide-details="auto"
                      />
                    </v-col>

                    <v-col cols="6">
                      <v-text-field
                        v-model="menu.route"
                        :rules="rules.route"
                        :label="$t('input.route_name')"
                        hide-details="auto"
                      />
                    </v-col>

                    <v-col cols="6">
                      <v-text-field
                        v-model="menu.url"
                        :rules="rules.url"
                        :label="$t('input.url_name')"
                        hide-details="auto"
                      />
                    </v-col>

                    <v-col cols="6">
                      <v-text-field
                        v-model="menu.icon"
                        :rules="rules.icon"
                        :label="$t('input.icon')"
                        hide-details="auto"
                      />
                    </v-col>

                    <v-col cols="6">
                      <v-text-field
                        v-model="menu.order"
                        :rules="rules.order"
                        :label="$t('input.order')"
                        type="number"
                        hide-details="auto"
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-combobox
                        v-model="menu.parent_id"
                        :items="optionMenus"
                        :label="$t('input.parent')"
                        item-text="name"
                        item-value="_id"
                        chips
                        hide-details="auto"
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            :disabled="data.disabled"
                          >
                            {{
                              data.item._id
                                ? displayMenu(data.item._id)
                                : displayMenu(data.item)
                            }}
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-col>

                    <v-col cols="12">
                      <v-combobox
                        :label="$t('input.permission')"
                        v-model="menu.permissions"
                        :items="optionPermissions"
                        item-text="permission_name"
                        item-value="_id"
                        clearable
                        multiple
                        :return-object="false"
                        hide-details="auto"
                      >
                        <template
                          v-slot:selection="{ attrs, item, select, selected }"
                        >
                          <v-chip
                            v-bind="attrs"
                            :input-value="selected"
                            @click="select"
                            close
                          >
                            <strong>{{
                              item._id
                                ? displayPermission(item._id)
                                : displayPermission(item)
                            }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-col>

                    <v-col cols="6">
                      <v-switch
                        :label="$t('input.visible')"
                        v-model="menu.is_visible"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
    },
    optionMenus: {
      type: Array,
      default: () => {
        return [];
      },
    },
    optionPermissions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    loading: {
      type: Boolean,
    },
    darkmode: {
      type: Boolean,
    },
    rules: {
      type: Object,
      default: function () {
        return {
          name: [(v) => !!v || "Name is required"],
          descr: [(v) => !!v || "description is required"],
          icon: [(v) => !!v || "description is required"],
          url: [(v) => !!v || "description is required"],
          route: [(v) => !!v || "description is required"],
          order: [(v) => !!v || "Order is required"],
        };
      },
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    menu: {
      type: Object,
      default: () => {
        return {
          name: null,
          descr: null,
          icon: null,
          url: null,
          route: null,
          parent_id: null,
          permissions: null,
          order: null,
          is_visible: true,
        };
      },
    },
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:isValidate", newValue);
      },
    },
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("submit");
      }
    },

    remove(item) {
      this.menu.permissions.splice(this.menu.permissions.indexOf(item), 1);
    },

    displayPermission(_id) {
      return this.optionPermissions.find((item) => item._id === _id)
        .permission_name;
    },

    displayMenu(_id) {
      return this.optionMenus.find((item) => item._id === _id).name;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
